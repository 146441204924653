<template>
  <div class="page__wrapper">
    <ag-grid
      ref="reviewReportGrid"
      pagination
      style="width:100%;height:100%;"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
  </div>
</template>

<script>
import Vue from 'vue'

const BASEURL = {
  list: '/jlr/tt/selfExaminationData/list',
  export: '/jlr/tt/selfExaminationData/export'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: ['handleDataSearch', 'handleExportClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" style="font-size:14px;" :content="$t('operation.download')">
        <el-button v-permission="'dealer_reviewReport:export'" type="text" icon="el-icon-download" @click="handleExportClick(params.data)"/>
      </el-tooltip>
    </div>`
})

export default {
  name: 'DealerReviewReport',
  data () {
    return {
      searchModel: {
        period: this.$moment().subtract(1, 'months').format('YYYYMM')
      },
      tableData: [],
      gridOptions: {},
      frameworkComponents: {}
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleExportClick: this.handleExportClick
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'period',
          type: 'Datepicker',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('dealer_finance.month'),
          component: { type: 'month', valueFormat: 'yyyyMM', clearable: false }
        },
        { slotName: 'tableOperation', col: { xs: 20, sm: 20, md: 20 }, style: { textAlign: 'right' }, labelWidth: '0' }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_finance.dealerName'),
          field: 'dealer.name',
          pinned: 'left',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.dealerCode'),
          field: 'dealer.code',
          pinned: 'left',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.area'),
          field: 'region',
          valueFormatter: params => this.$getDictLabel({ type: 'sys_office_region', value: params.value }),
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.group'),
          field: 'dealerParent',
          valueFormatter: params => params.data.dealerParent ? (params.data.dealerParent.name || '') : '',
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return (valueA && valueB) ? valueA.name.localeCompare(valueB.name) : -1
          },
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.version'),
          field: 'version',
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.cycle'),
          field: 'period',
          valueFormatter: params => params.value ? this.$moment(params.value, 'YYYYMM').format('YYYY年MM月') : '',
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.status'),
          field: 'bizStatus',
          valueFormatter: params => this.$getDictLabel({ type: 'tt_self_examination_data_head_biz_status', value: params.value }),
          minWidth: 160
        },
        {
          headerName: this.$t('field.operation'),
          suppressSizeToFit: true,
          width: 68,
          pinned: 'right',
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      params.filter.push({ left: 'isSubmit', operate: '=', right: 'Y' })
      params.filter.push({ left: 'bizStatus', operate: '=', right: 'V3' })
      params.filter.push({ left: 'reviewRelease', operate: '=', right: 'tt_review_release_1' })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleExportClick (row) {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.export, { id: row.id })
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('tip.exportSuccess') })
        })
        .finally(() => {
          loadingFlag.close()
        })
      this.isShowTemplateDialog = false
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>
